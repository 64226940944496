<template>
  <div class="row justify-content-end">
    <div class="col-md-5 col-12">
      <div class="top-product">
        <template v-if="subCategory.titleHEB && parseInt($route.params.subCategoryId) !== 0">
          <ul class="list-unstyled list-inline">
            <li class="list-inline-item nextProd" v-if="product.nextProd !== undefined" @click="deleteIsFromCategory">
              <router-link :to="{name: 'product', params: {subCategoryId: subCategory.id, productId: product.nextProd}}">
                מוצר הבא
              </router-link>
            </li>

            <li class="list-inline-item prevProd" v-if="product.prevProd !== undefined" @click="deleteIsFromCategory">
              <router-link :to="{name: 'product', params: {subCategoryId: subCategory.id, productId: product.prevProd}}">
                מוצר קודם
              </router-link>
            </li>
          </ul>

          <a href="#" @click.prevent="goBack" :class="['back-to-categories', {'back-button-loading': backButtonLoading}]">
            חזרה ל{{ subCategory.titleHEB }}
            <div class="spinner-border spinner-border-sm text-body" role="status" v-if="backButtonLoading">
              <span class="sr-only" v-text="'Loading...'" />
            </div>
            <span class="go-back-mobile-icon" v-else />
            <span class="go-back-mobile" v-text="'חזרה'" />
          </a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BreadCrumbs',

  data() {
    return {
      backButtonLoading: false,
    }
  },

  computed: {
    subCategory() {
      return this.$store.getters.subCategory
    },
    product() {
      return this.$store.getters.product
    },
    scrollPosition() {
      return this.$store.getters.scrollPosition
    }
  },

  methods: {
    goBack() {
      this.backButtonLoading = true

      window.isFromCategory
        ? this.$router.back()
        : this.$router.replace({name: 'subCategories', params: { subCategoryId: this.subCategory.id }})
    },

    deleteIsFromCategory() {
      delete window.isFromCategory
    }
  },

  destroyed() {
    this.deleteIsFromCategory()
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.top-product{
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.3rem;
}
.top-product ul{
  margin-bottom: 0;
}
.top-product ul:before, .top-product ul:after{
  color: #8D8D8D;
}
.top-product li:first-child:after{
  content: '/';
  color: #8D8D8D;
  margin: 0 .3rem 0 .6rem;
}
.top-product a{
  color: #8D8D8D;
  font-size: .8rem;
  direction: rtl;
}
.prevProd::after {
  content: '>';
  margin-left: .3rem;
  color: #8D8D8D;
}
.nextProd::before {
  content: '<';
  margin-right: .3rem;
  color: #8D8D8D;
}
.top-product a.back-to-categories .spinner-border{
  margin-right: .3rem;
}
.go-back-mobile{
  display: none;
  font-size: .55rem;
  line-height: 1;
  color: #000;
}
.go-back-mobile{
  display: none;
}

@include media-breakpoint-down(sm) {
  .go-back-mobile{
    display: block;
  }
  .top-product{
    align-items: center;
    padding: .4rem 15px;
    margin-bottom: 0;
    flex-direction: row-reverse;
    background-color: #fff;
    position: fixed;
    top: 63px;
    left: 0;
    right: 0;
    z-index: 10;
  }
  .top-product ul{
    line-height: 1;
  }
  .top-product li:first-child{
    margin-right: 3rem;
  }
  .top-product li:first-child:after{
    content: none;
  }
  .top-product a.back-to-categories{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 24px;
    height: 1.8rem;
    font-size: 0;
    text-decoration: none;
  }
  .go-back-mobile-icon{
    display: block;
    width: 24px;
    height: 15px;
    background: url('../../assets/images/back.svg') no-repeat center top;
    background-size: contain;
  }
  .top-product a.back-button-loading{
    background: none;
  }
  .spinner-border{
    font-size: 15px;
  }
}
</style>
