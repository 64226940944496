<template>
  <div id="product">
    <Loading v-if="loading" />

    <section class="site-section" v-else>
      <div class="container-fluid">
        <div class="section-body">
          <BreadCrumbs />

          <div class="row">
            <div class="col-md-7">
              <div id="mainSliderDesktop" v-if="device.desktop() || device.tablet()">
                <SliderProducts :toggle="toggleFavorite" :isModal="false" />
              </div>

              <div id="mainSliderMobile" v-if="device.mobile()">
                <MainSliderMobile :toggle="toggleFavorite" />
              </div>

              <div class="slides" v-if="completeLook.length && (device.desktop() || device.tablet())">
                <h3 v-text="'השלימי את הלוק'" />
                <HooperSliderProducts :products="completeLook" :toggle="toggleFavorite" :settings="settings" :fromMainPage="false" />
              </div>
            </div>

            <div class="col-md-5">
              <ProductInfo :toggle="toggleFavorite" :isModal="false" />

              <div class="slides-mobile" v-if="completeLook.length && device.mobile()">
                <h3 v-text="'השלימי את הלוק'" />
                <HooperSliderProducts :products="completeLook" :toggle="toggleFavorite" :settings="settings" :fromMainPage="false" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import device from 'current-device'
import BreadCrumbs from '@/components/product/BreadCrumbs'
import SliderProducts from '@/components/product/SliderProducts'
import MainSliderMobile from '@/components/product/MainSliderMobile'
import HooperSliderProducts from '@/components/HooperSliderProducts'
import ProductInfo from '@/components/product/ProductInfo'

export default {
  name: 'Product',

  components: {
    BreadCrumbs, SliderProducts, MainSliderMobile, HooperSliderProducts, ProductInfo,
  },

  props: ['subCategoryId', 'productId'],

  metaInfo() {
    return {
      title: process.env.VUE_APP_NAME+' | '+this.product.titleHEB+' | '+process.env.VUE_APP_NAME_HEB,
      meta: [
        { name: 'description', content: this.product.textHEB ? this.product.textHEB : '' },
        // { vmid: 'og:title', property: 'og:title', content: 'Vue App' },
        // { vmid: 'og:description', property: 'og:description', content: 'Vue App' },
      ],
    }
  },

  data() {
    return {
      device,
      loading: false,
      settings: {
        itemsToShow: 3,
        itemsToSlide: 1,
        initialSlide: 0,
        wheelControl: false,
        keysControl: false,
        info: true,
      },
    }
  },

  computed: {
    subCategory() {
      return this.$store.getters.subCategory
    },
    product() {
      return this.$store.getters.product
    },
    completeLook() {
      return this.$store.getters.completeLook
    },
  },

  methods:{
    async toggleFavorite() {
      if (this.$store.getters.isLogin) {
        await this.$store.dispatch('updateFavProduct', {productId: this.productId})
        this.product.favorite = !this.product.favorite
      } else {
        this.$router.push({
          name: 'login',
          params: {
            favorite: {
              subCategoryId: this.subCategoryId,
              productId: this.productId,
            }
          }
        })
      }
    },

    async loadData() {
      this.loading = true
      await this.$store.dispatch('getSubCategory', { subCategoryId: this.subCategoryId })
      await this.$store.dispatch('getProduct', { productId: this.productId })
      window.scrollTo(0, 0)
      this.loading = false
    }
  },

  watch: {
    async $route() {
      await this.loadData()
    },
  },

  beforeRouteEnter(to, from, next) {
    window.isFromCategory = from.name === 'subCategories'
    next()
  },
    
  async created() {
    await this.loadData()
  },

  destroyed() {
    this.$store.commit('setCompleteLook', [])
    this.$store.commit('setProduct', {})
    this.$store.commit('setSelectedColorId', null)
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

#product {
  min-height: 40vh;
}
#product .container-fluid{
  margin-left: 6rem;
  margin-right: 2.6rem;
  width: inherit;
}
.slides{
  margin-top: 4.3rem;
}
.slides h3{
  text-align: center;
  font-size: 1.7rem;
  margin-bottom: 2.6rem;
}
.slides-mobile{
  display: none;
}
#mainSliderMobile{
  display: none;
}

@include media-breakpoint-down(sm) {
  #product .container-fluid{
    margin-left: inherit;
    margin-right: inherit;
    width: 100%;
  }
  #mainSliderDesktop{
    display: none;
  }
  #mainSliderMobile{
    display: block;
  }
  .section-body{
    padding-top: 44px;
  }
  .slides {
    display: none;
  }
  .slides-mobile{
    display: block;
  }
  .slides-mobile h3{
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1.4rem;
  }
}

@include media-breakpoint-down(md) {
  #product .container-fluid{
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}
</style>
