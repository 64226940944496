<template>
  <div id="hooperMainSliderMobile">
    <hooper ref="carousel" :itemsToShow="1" :itemsToSlide="1" :initialSlide="0"  :shortDrag="false" :trimWhiteSpace="false">
      <slide v-for="(slider, index) of sliders" :key="index" :index="index" class="side">
        <div :class="['product-favorite-product', {active: product.favorite}]" @click.prevent="toggle"></div>
        
        <img :src="slider.path" alt="" v-if="slider.type === 'image'" class="img-fluid">
        <!-- <img :src="getImageSoldOout()" alt="Out Of stock" ref="firstSlide" class="img-fluid soldout" v-if="outOfStock" /> -->

        <div v-if="slider.type === 'video'" class="video_frame">
          <!-- <Loading /> -->
          <video-player class="product-video iframe_class"
            ref="videoPlayerRef"
            :options="playerOptions"
            @ready="onPlayerReady"
            v-if="product.defaultProd.mainVideo && !vimeo"
             />

        <iframe v-if="product.defaultProd.mainVideo && vimeo" autoplay :src="`https://player.vimeo.com/video/${product.defaultProd.mainVideo}?autoplay=1&loop=1&title=0&byline=0&portrait=0`" class="product-video" style="width:289px;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="" title="Nested Sequence 77_2" data-ready="true"></iframe>


        </div>
      </slide>

      <navigation slot="hooper-addons" />
    </hooper>

  </div>
</template>

<script>
import { Hooper, Slide, Navigation } from 'hooper'
import mainMixin from '@/mixins/main.mixin';
// import dashjs from 'dashjs'
import { videoPlayer } from 'vue-video-player'
import 'video.js' // OR: import videojs from 'video.js';
import 'videojs-contrib-hls'
import 'video.js/dist/video-js.css'

export default {
  name: 'MainSliderMobile',
  mixins:[ mainMixin ],
  components: {
    Hooper, Slide, Navigation, videoPlayer
  },

  props: ['toggle'],

  data() {
    return {
      vimeo: false,
      playerOptions: {
        name: 'player',
        autoplay: true,
        loop:true,
        muted:true,
        playsinline:true,
        sources: [{
          type: 'application/x-mpegurl',
          src: '',
          withCredentials: false,
        }],
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
        flash: { hls: {withCredentials: false } },
        html5: { hls: {withCredentials: false } },
        poster: this.thumbnail,
      },
      pauseClick: false,
      autoPlay: '1',
      autoPause: '0',
      outOfStock: true
    }
  },

  computed: {
    player() {
      return this.$refs.videoPlayerRef.player;
    },
    product() {
      return this.$store.getters.product
    },
    selectedColorId() {
      return this.$store.getters.selectedColorId
    },
    sliders() {
      const sliders = []

      if('defaultProd' in this.product) {
        this.product.defaultProd.images.forEach(image => sliders.push({
          type: 'image',
          path: image,
        }))

        if(this.product.defaultProd.mainVideo) {
          sliders.splice(1, 0, {
            type: 'video',
            path: this.product.defaultProd.mainVideo
          })
        }
        // console.log(sliders)
        return sliders
      }
      return []
    }
  },
  created() {
    if(this.product.defaultProd.mainVideo && this.product.defaultProd.mainVideo.search('mp4') > 0){
      this.playerOptions.sources = [{
        type: 'application/x-mpegurl',
        src: `https://video.gong-online.co.il/hls/${this.product.defaultProd.mainVideo}/index.m3u8`
      }]
      this.vimeo = false;
    }else if(this.product.defaultProd.mainVideo){
      this.vimeo = true;
      this.playerOptions.sources = [{
        type: 'application/x-mpegurl',
        src: this.product.defaultProd.mainVideo
      }]
    }
    this.handleOutOfStock();
  },
  watch: {
    product() {
      this.$refs.carousel.slideTo(0)
    },
    selectedColorId() {
      if(this.product.defaultProd.mainVideo && this.product.defaultProd.mainVideo.search('mp4') > 0){
        this.playerOptions.sources = [{
          type: 'application/x-mpegurl',
          src: `https://video.gong-online.co.il/hls/${this.product.defaultProd.mainVideo}/index.m3u8`
        }]
        this.vimeo = false;
      }else if(this.product.defaultProd.mainVideo){
        this.vimeo = true;
        this.playerOptions.sources = [{
          type: 'application/x-mpegurl',
          src: this.product.defaultProd.mainVideo
        }]
      }
      if(this.product.defaultProd)
        this.handleOutOfStock();
    },
  },
  mounted() {
      window.vuePlayerModal = this;
  },

  methods: {
    onPlayerReady(player) {
    },
    pause() {
      this.pauseClick = !this.pauseClick
      const newAutoPlay = this.pauseClick ? '0' : '1'
      const newAutoPause = this.pauseClick ? '1' : '0'

      const iframeClass = document.getElementsByClassName('iframe_class')
      iframeClass.forEach(iframe => {
        iframe.src = iframe.src
          .replace('autoplay='+this.autoPlay, 'autoplay='+newAutoPlay)
          .replace('autopause='+this.autoPause, 'autopause='+newAutoPause)
      })

      this.autoPlay = newAutoPlay
      this.autoPause = newAutoPause
    },
    handleOutOfStock(){
      this.outOfStock = true;
      this.product.sizes.forEach(prod => {
        if(prod.amount > 0 ){
          this.outOfStock = false;
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.iframe_class{
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@include media-breakpoint-down(sm) {
  #mainSliderMobile {
    overflow-x: hidden;
  }
  .side{
    position:relative;
  }
  .soldout{
    width: 50% !important;
    height: auto !important;
    position: absolute;
    right: 0;
  }
  #hooperMainSliderMobile .hooper {
    height: 30.9rem;
    text-align: center;
  }
  #hooperMainSliderMobile .hooper img {
    width: auto;
    height: 100%;
  }
  .video_frame {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .video_frame iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 32.38rem;
    margin: 0 auto;
  }
  .overIframe{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
  }
}

@media (max-width: 359px) {
  #hooperMainSliderMobile .hooper {
    height: 24.38rem;
    text-align: center;
  }
  .video_frame iframe {
    width: 100%;
    height: 24.38rem;
  }
}
</style>
